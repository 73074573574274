import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useRouter } from 'next/router';

import CartDrawer from 'components/parts/CartDrawer';
import LocaleSelector from 'components/parts/LocaleSelector';
import LocaleSwitcher from 'components/parts/LocaleSwitcher';
import { ECImage, Icon, Button, ECLink } from 'components/ui';
import { useHeaderActions } from 'context/HeaderActionsProvider';
import { useModal } from 'context/ModalProvider';
import { useCart } from 'context/ShopifyCartProvider';
import { useSlideOver } from 'context/SlideOverProvider';
import useTrackingEvents from 'services/analytics/useTrackingEvents';
import { useSessionContext } from 'services/session';
import { useTranslations } from 'services/translations/TranslationsProvider';

import DesktopNavItemLink from './DesktopNavItemLink';
import styles from './Header.module.css';
import MobileNavItemLink from './MobileNavItemLink';
import getNavItems from './navItems';

type Props = {
  translatedSlugs?: {
    en: string;
    nl: string;
    fr: string;
    de: string;
    it: string;
    es: string;
  };
};

const Header: React.FC<Props> = ({ translatedSlugs }) => {
  const [scrollDir, setScrollDir] = useState<'up' | 'down'>('down');
  const [isTop, setIsTop] = useState<boolean>(true);

  const { cart, cartStates } = useCart();
  const { t } = useTranslations('header');
  const { trackCtaClicked } = useTrackingEvents();
  const { locale } = useRouter();

  const navItems = getNavItems(t, locale).filter(Boolean);
  const { openModal, closeModal, setModalConfig } = useModal();

  const { openSlideOver, closeSlideOver, setSlideOverConfig } = useSlideOver();

  const { addActionListener } = useHeaderActions();

  // Header animations
  useEffect(() => {
    const threshold = 0;
    const offset = 120;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up');
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const updateTop = () => {
      setIsTop(!(lastScrollY > offset));
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateScrollDir();
          updateTop();
        });
        ticking = true;
      }
    };

    addActionListener({
      actions: ['show'],
      callback: () => {
        setIsTop(true);
      },
    });

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDir, isTop, addActionListener]);

  // Open locale selector in modal
  const openLocaleModal = () => {
    setModalConfig({
      size: 'sm',
      title: t('localeModal:title'),
      children: (
        <LocaleSelector
          onSuccess={closeModal}
          closeModal={closeModal}
          translatedSlugs={translatedSlugs}
        />
      ),
      allowOverflow: true,
    });
    openModal();
  };

  const { user } = useSessionContext();

  // Mobile navigation markup
  const mobileNavigationMarkup = (
    <div className="flex flex-col flex-1">
      <nav className="flex flex-col flex-1 text-lg flex-grow">
        <ul className="flex flex-col divide-y divide-blue-200 border-b border-blue-200">
          {navItems.map(
            (navItem) =>
              navItem.mobile && (
                <li key={navItem.label}>
                  <MobileNavItemLink navItem={navItem} />
                </li>
              ),
          )}
        </ul>
        <div className="px-4 py-6 flex flex-col flex-grow">
          <div className="flex flex-col gap-4 flex-grow justify-end font-rooney text-gray-500 text-lg font-bold">
            <ECLink href="/account/" className="flex items-center gap-1">
              <Icon name="account-mini" className="w-6 h-6 m-[0.25rem] text-gray-500 shrink-0" />
              <span className="transition hover:text-cyan-400">
                {user ? t('account') : t('login')}
              </span>
            </ECLink>
            <ECLink href="/store-locator/" className="flex items-center gap-1">
              <Icon name="location-marker-2" className="w-5 h-5 m-[0.375rem] text-gray-500" />
              <span className="transition hover:text-cyan-400">{t('storeFinder')}</span>
            </ECLink>
            <Button
              legacy
              className="!rounded-full border border-blue-200 !px-4 !py-2 w-fit hover:bg-white"
              onClick={() => {
                closeSlideOver();
                openLocaleModal();
                trackCtaClicked({ ctaId: 'site_navigation_locale_selector' });
              }}
              color="white-gray-hover"
              aria-label={t('countryLanguage')}
            >
              <LocaleSwitcher mobile />
            </Button>
          </div>
        </div>
      </nav>
    </div>
  );

  // Open mobile navigation in slide over
  const openMobileNav = () => {
    setSlideOverConfig({
      size: 'sm',
      position: 'left',
      children: mobileNavigationMarkup,
    });
    openSlideOver();
  };

  /** Open cart drawer on mobile */
  const openCartDrawer = () => {
    setSlideOverConfig({
      size: 'md',
      position: 'right',
      title: t('cartDrawer:title'),
      children: <CartDrawer />,
    });
    openSlideOver();
    trackCtaClicked({ ctaId: 'site_navigation_open_cart_drawer' });
  };

  const headerClass = classNames(
    'g-site-header relative top-0 left-0 w-full text-base lg:z-40 lg:fixed',
    styles['site-header'],
    scrollDir === 'down' ? styles.unpinned : styles.pinned,
    isTop ? styles.top : styles['not-top'],
  );

  return (
    <header id="site-header" className={headerClass}>
      <div className="fixed top-0 left-0 w-full bg-white z-50 border-b border-blue-200 h-16 lg:h-20 lg:relative">
        <div className="container flex items-center h-full justify-between w-full">
          <button
            className="lg:hidden flex items-center justify-left w-10"
            onClick={openMobileNav}
            type="button"
          >
            <Icon name="hamburger" className="w-8 text-gray-500" />
          </button>
          <ECLink href="/">
            <ECImage
              img={{ src: '/images/logo-brand-light.svg', alt: 'Edgard &amp; Cooper' }}
              className="w-20 lg:w-24"
              width="80"
              height="40"
            />
          </ECLink>
          {/* Desktop left */}
          <nav className="hidden lg:block absolute left-1/2 transform -translate-x-1/2">
            <ul className="flex items-center gap-x-4">
              {navItems.map(
                (navItem) =>
                  navItem.desktop && (
                    <li key={navItem.label}>
                      <DesktopNavItemLink navItem={navItem} />
                    </li>
                  ),
              )}
            </ul>
          </nav>
          {/* Desktop right */}
          <ul className="flex items-center gap-2 lg:gap-x-6">
            <li className="hidden lg:block">
              <button
                className="uppercase transition hidden items-center lg:flex group hover:bg-cyan-200 p-2 -m-2 rounded-sm"
                onClick={() => {
                  openLocaleModal();
                  trackCtaClicked({ ctaId: 'site_navigation_locale_selector' });
                }}
                type="button"
                aria-label={t('countryLanguage')}
                data-testid="countryLanguageSelector"
              >
                <LocaleSwitcher />
              </button>
            </li>
            <li className="hidden lg:block">
              <ECLink href="/account/" data-testid="account" className="flex">
                <Icon
                  name="account"
                  className="w-8 text-gray-500 hover:text-cyan-400 rounded-full transition"
                />
              </ECLink>
            </li>
            <li>
              <button
                type="button"
                onClick={openCartDrawer}
                className="flex relative items-center justify-center group"
                data-testid="cartDrawerBtn"
              >
                <Icon
                  name={
                    cart?.totalQuantity === 0 || !cart?.totalQuantity
                      ? 'foodbowl-empty'
                      : 'foodbowl-full'
                  }
                  aria-label={t('cart')}
                  className="w-8 text-gray-500 group-hover:text-cyan-400 rounded-full transition -mb-[3px]"
                />
                <div
                  className={`absolute -top-[1px] right-1 transform translate-x-1/2 -translate-y-1/4 rounded-full w-5 h-5 ${
                    cartStates.isCartError ? 'bg-red-400' : 'bg-cyan-400'
                  }`}
                >
                  <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-rooney text-sm font-bold">
                    {cartStates.isCartError ? '!' : cart?.totalQuantity}
                  </span>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
