/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import i18next, { TOptionsBase } from 'i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import getStickerBanners from 'api/client/queries/stickerBanner';
import { CountryWithProvinces } from 'api/generated-types';
import { useCountryContext } from 'context/CountryContextProvider';
import { useSessionContext } from 'services/session';
import { useTranslations } from 'services/translations/TranslationsProvider';
import formatPrice from 'utils/formatPrice';

const getFreeShippingFrom = (countryCode: string, shippingCountry: CountryWithProvinces) => {
  if (!countryCode) return null;
  return { currencyCode: shippingCountry?.currencyCode, amount: shippingCountry?.freeShippingFrom };
};

export type PageBannerOptions = {
  hide?: boolean;
  textOverride?: {
    text?: string;
    translationKey?: string;
    translationOptions?: TOptionsBase & Record<string, string>;
  };
};

const PageBanner: React.FC<PageBannerOptions> = ({ hide = false, textOverride }) => {
  const { locale } = useRouter();
  const {
    countryCode,
    shippingCountries,
    isLoading: isLoadingCountryContext,
  } = useCountryContext();
  const [pageBanners, setPageBanners] = useState(null);

  const { t } = useTranslations('header');

  const shippingCountry = shippingCountries?.find((el) => el.code.toUpperCase() === countryCode);
  const freeShippingFrom = getFreeShippingFrom(countryCode, shippingCountry);

  const bannerOther = pageBanners?.find((banner) => banner);

  const { user, isLoading: isLoadingUser } = useSessionContext();

  useEffect(() => {
    if (countryCode && !isLoadingCountryContext && !isLoadingUser && !textOverride) {
      getStickerBanners({ countryCode, locale, limit: 3 }).then((banners: any) => {
        setPageBanners(banners?.getStickerBanners);
        banners?.getStickerBanners?.forEach((banner, index) => {
          i18next.addResource(locale, 'header', `pageBannerText${index}`, banner.displayText);
        });
      });
    } else if (textOverride) {
      setPageBanners([{ displayText: textOverride }]);
    }
  }, [countryCode, locale, isLoadingCountryContext, user, isLoadingUser, textOverride]);

  const pageBannerHTML = (index) =>
    textOverride ? (
      <div className="text-purple-700 leading-5">
        {textOverride.text ?? t(textOverride.translationKey, textOverride.translationOptions)}
      </div>
    ) : freeShippingFrom?.amount != null ? (
      <div
        className="leading-5"
        style={{
          color: '#4F006D', // purple-700
        }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t(`pageBannerText${index}`, {
            country: shippingCountry?.name,
            'freeshipping-value': formatPrice(
              freeShippingFrom?.amount,
              freeShippingFrom?.currencyCode,
              locale,
              {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              },
            ),
          }),
        }}
      />
    ) : (
      <div className="leading-5 text-purple-700">{bannerOther.displayText}</div>
    );

  if (hide) {
    return null;
  }

  if (!pageBanners?.length) {
    return <div className="h-12 lg:h-10 bg-yellow-600" />;
  }

  return (
    <div
      style={{
        backgroundColor:
          /* pageBanners[0].backgroundHexColor
          ? pageBanners[0].backgroundHexColor
          : */ '#fedd00', // yellow-600
      }}
      className="py-[0.625rem]"
    >
      <div className="container relative flex items-center">
        <Swiper
          loop
          autoplay={
            pageBanners?.length > 1
              ? { delay: 8000, disableOnInteraction: false, pauseOnMouseEnter: true }
              : false
          }
          modules={[Autoplay]}
          className="w-full text-base font-rooney font-bold z-40 text-left md:text-center leading-5"
          allowSlideNext={pageBanners?.length > 1}
          allowSlidePrev={pageBanners?.length > 1}
        >
          {pageBanners.map((pageBanner, index) => (
            <SwiperSlide
              key={pageBanner.displayText}
              style={{
                height: 'auto',
              }}
            >
              {pageBanner.link ? (
                <Link
                  href={pageBanner.link}
                  className="cursor-pointer h-full flex items-center md:justify-center"
                  target={pageBanner.openLinkInNewTab ? '_blank' : '_self'}
                >
                  {pageBannerHTML(/* pageBanner, */ index)}
                </Link>
              ) : (
                <div className="cursor-default h-full flex items-center md:justify-center">
                  {pageBannerHTML(/* pageBanner, */ index)}
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PageBanner;
