import { useRouter } from 'next/router';

import { CountryFlag } from 'components/ui';
import { useCountryContext } from 'context/CountryContextProvider';
import { useTranslations } from 'services/translations/TranslationsProvider';
import { regionInLocale } from 'utils/localeHelpers';

type Props = { mobile?: boolean };

const LocaleSwitcher: React.FC<Props> = ({ mobile = false }) => {
  const { countryCode, isLoading: isLoadingCountryContext } = useCountryContext();
  const { locale } = useRouter();
  const { t } = useTranslations('header');

  return (
    <div className="flex items-center gap-2">
      <CountryFlag
        countryCode={!isLoadingCountryContext ? countryCode : 'OTHER'}
        className="my-auto group-hover:brightness-105 transition"
      />
      {mobile && (
        <span className="capitalize font-rooney text-gray-500 font-bold text-base">
          {
            // eslint-disable-next-line no-nested-ternary
            isLoadingCountryContext ? (
              // placeholder
              <div className="w-20 h-5 bg-cyan-200 inline-block align-text-bottom" />
            ) : countryCode.toUpperCase() === 'OTHER' ? (
              t('other')
            ) : (
              regionInLocale(countryCode, locale)
            )
          }{' '}
          <span className="uppercase">({locale})</span>
        </span>
      )}
    </div>
  );
};

export default LocaleSwitcher;
