import { FC, PropsWithChildren, useEffect } from 'react';

import { useRouter } from 'next/router';
import Script from 'next/script';

import PageBanner, { PageBannerOptions } from 'components/parts/PageBanner';
import { Loader } from 'components/ui';
import { useCountryContext } from 'context/CountryContextProvider';

import Footer from '../Footer/Footer';
import Header from '../Header';

interface Props {
  isLoading?: boolean;
  isIdle?: boolean;
  className?: string;
  translatedSlugs?: {
    en: string;
    nl: string;
    fr: string;
    de: string;
    it: string;
    es: string;
  };
  pageBannerOptions?: PageBannerOptions;
}

const Layout: FC<PropsWithChildren<Props>> = ({
  isLoading,
  isIdle,
  className,
  children,
  translatedSlugs,
  pageBannerOptions,
}) => {
  const { countryCode } = useCountryContext();
  const isFrance = countryCode === 'FR';
  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
  const router = useRouter();
  const enableRealyticsTrack = isProduction && isFrance;

  useEffect(() => {
    if ((window as any).RY && isFrance) {
      (window as any).RY.page();
    }
  }, [router.query, isFrance]);

  return (
    <>
      <div
        id="layout"
        className={`flex flex-col min-h-screen relative z-0 pt-16 lg:pt-20 ${
          className ? `${className}` : ''
        }`}
      >
        <Header translatedSlugs={translatedSlugs} />
        <main className="flex flex-col grow">
          {(isLoading || isIdle) && <Loader size="large" className="md:h-96" />}
          {!isLoading && (
            <>
              {/* Notification banner */}
              <PageBanner
                hide={pageBannerOptions?.hide}
                textOverride={pageBannerOptions?.textOverride}
              />
              {children}
            </>
          )}
        </main>
        <Footer />
      </div>
      {/* GTM testing without Segment: 29/10/2024 */}
      <Script async id="gtm">
        {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-W8BF8SL');
              `}
      </Script>
      {enableRealyticsTrack && (
        <Script async id="realytics" data-cookieconsent="ignore">
          {`
          window.RY=(function(e){var t=["identify","track","trackLink","trackForm","transaction","page","profile","sync","consent","reject","start","stop","optin","optout"];var n="realytics";var r=function(e){return!!(e&&(typeof e=="function"||typeof e=="object"))};var i=function(e,t){return function(){var n=Array.prototype.slice.call(arguments);if(!e[t])e[t]=[];e[t].push(n?n:[]);if(!e["_q"])e["_q"]=[];e["_q"].push(t)}};var s=function(r){for(var s=0;s < t.length;s++){var o=t[s];if(r)e[r][o]=i(e._q[r],o);else e[o]=e[n][o]=i(e._q[n],o)}};var o=function(t,r,i){var o=t?t:n;if(!e[o])e[o]={};if(!e._q[o])e._q[o]={};if(r)e._q[o]["init"]=[[r,i?i:null]];s(t)};if(!e._v){if(!e._q){e._q={};o(null,null,null)}e.init=function(e,n){var i=n?r(n)?n["name"]?n["name"]:null:n:null;if(i&&t)for(var s=0;s < t.length;s++)if(i==t[s]||i=="init")return;o(i,e,r(n)?n:null);var u=function(e){var t=document.createElement("script");t.type="text/javascript";t.async=true;t.src=("https:"==document.location.protocol?"https://":"http://")+e;var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)};u("i.realytics.io/tc.js?cb="+(new Date).getTime());u("cdn-eu.realytics.net/realytics-1.2.min.js")}}return e})(window.RY||{});

          RY.init("ry-3dg4rc0");
      `}
        </Script>
      )}
    </>
  );
};
export default Layout;
