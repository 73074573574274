import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import Link from 'next/link';
import sanitizeHtml from 'sanitize-html-react';

import { ECImage, Icon } from 'components/ui';
import { useModal } from 'context/ModalProvider';
import { useTracking } from 'context/TrackingProvider';
import { getClientSideCookie } from 'services/session';
import { useTranslations } from 'services/translations/TranslationsProvider';
import { CookieNames } from 'utils/constants';
import { capitalizeFirstLetter } from 'utils/stringUtils';

import PetSurveyV2Input from '../PetSurveyV2/PetSurveyV2Input';

interface Props {
  range?: 'dog' | 'cat';
  theme?: 'dark' | 'light';
  isCard?: boolean;
  size?: 'small' | 'medium';
  ctaId?: string;
  className?: string;
}

const ProductQuizCard: React.FC<Props> = ({
  range,
  theme = 'light',
  size = 'small',
  ctaId,
  isCard = 'false',
  className: additionalClassName,
}) => {
  const { t } = useTranslations('header');
  const { closeModal } = useModal();
  const [surveyAnswers, setSurveyAnswers] = useState<PetSurveyV2Input>(null);
  const {
    events: { trackCtaClicked },
  } = useTracking();

  const title = surveyAnswers
    ? sanitizeHtml(
        t('productQuizCompletedTitle', {
          name: `<span class="font-bold">${capitalizeFirstLetter(surveyAnswers?.name)}</span>`,
        }),
      )
    : t('productQuizTitle', {
        animal: range === 'cat' ? t('productQuizTitleAnimalCat') : t('productQuizTitleAnimalDog'),
      });

  const ctaLabel = surveyAnswers ? t('productQuizCompletedCta') : t('productQuizCta');

  // eslint-disable-next-line no-nested-ternary
  const ctaUrl = range === 'cat' ? '/quiz/cat/' : '/quiz/dog/';

  /**
   * Get answers from survey cookie
   */
  useEffect(() => {
    const surveyAnswersFromCookie = getClientSideCookie(
      range === 'cat' ? CookieNames.PetSurveyCat : CookieNames.PetSurveyDog,
    );
    if (surveyAnswersFromCookie) {
      setSurveyAnswers(JSON.parse(surveyAnswersFromCookie));
    }
  }, [range]);

  return (
    <Link
      href={ctaUrl}
      onClick={() => {
        if (ctaId) {
          trackCtaClicked({ ctaId });
        }
        closeModal();
      }}
      className={classNames(
        'flex flex-col transition relative group overflow-hidden min-h-[100px] ',
        theme === 'light' && 'bg-blue-200 bg-opacity-50 hover:bg-opacity-100',
        theme === 'dark' && 'bg-white bg-opacity-100 hover:bg-opacity-100 ',
        size === 'small' && 'p-4',
        size === 'medium' && 'p-6',
        isCard && 'rounded-lg',
        isCard && theme === 'light' && 'border border-blue-200',
        isCard &&
          theme === 'dark' &&
          'border border-gray-75 border-opacity-100 hover:border-cyan-400',
        additionalClassName,
      )}
    >
      <div className="flex flex-col flex-grow gap-y-2 font-rooney w-1/2 min-w-[75%] max-w-[150px]">
        <p
          className="text-gray-500 font-normal text-base flex-grow"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className="flex items-center text-gray-500 whitespace-nowrap">
          <span className="flex text-base font-bold border-gray-500">{ctaLabel}</span>
          <Icon
            name="chevron-right"
            className="w-5 inline translate-y-[1px] transition group-hover:translate-x-[1px] flex-none"
          />
        </div>
      </div>

      {range === 'cat' ? (
        <ECImage
          img={{
            src: '/images/navigation/nav-cat-illustration.png',
          }}
          className="absolute w-[80px] left-[100%] ml-[-65px] top-[100%] mt-[-75px] pointer-events-none"
        />
      ) : (
        <ECImage
          img={{
            src: '/images/navigation/nav-dog-illustration.png',
          }}
          className="absolute w-[160px] left-[100%] ml-[-75px] top-[100%] mt-[-75px] pointer-events-none"
        />
      )}
    </Link>
  );
};

export default ProductQuizCard;
