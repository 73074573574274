import { ProductVariant } from 'api/generated-types';

const isProductVariantAllowedInSubscription = (productVariant: ProductVariant) => {
  if (
    productVariant.product.content.handle === 'christmas-gift-box' ||
    productVariant.universalKey.includes('FESTIVE') ||
    productVariant.content.isBundle
  ) {
    return false;
  }
  return true;
};

// eslint-disable-next-line import/prefer-default-export
export { isProductVariantAllowedInSubscription };
