import Link from 'next/link';
import { useRouter } from 'next/router';

import LocaleSelector from 'components/parts/LocaleSelector';
import LocaleSwitcher from 'components/parts/LocaleSwitcher';
import PaymentMethods from 'components/parts/PaymentMethods';
import Sticker from 'components/parts/Sticker';
import { ECLink, Icon } from 'components/ui';
import ECImage from 'components/ui/ECImage';
import Section from 'components/ui/Section';
import { useCountryContext } from 'context/CountryContextProvider';
import { useModal } from 'context/ModalProvider';
import useTrackingEvents from 'services/analytics/useTrackingEvents';
import { useTranslations } from 'services/translations/TranslationsProvider';
import useStaticTranslate from 'utils/hooks/useStaticTranslate';
import { getPagePath } from 'utils/page-paths';

import getFooterNavItems from './navItems';
import MobileNavItemLink from '../Header/MobileNavItemLink';

type Props = {
  translatedSlugs?: {
    en: string;
    nl: string;
    fr: string;
    de: string;
    it: string;
    es: string;
  };
};

const Footer: React.FC<Props> = ({ translatedSlugs }) => {
  const { countryCode, isLoading: isLoadingCountryContext } = useCountryContext();
  const { t } = useTranslations('footer');
  const { staticTranslate, staticTranslations } = useStaticTranslate();
  const { trackCtaClicked } = useTrackingEvents();
  const { locale } = useRouter();
  const { openModal, closeModal, setModalConfig } = useModal();

  // Open locale selector in modal
  const openLocaleModal = () => {
    setModalConfig({
      size: 'sm',
      title: t('localeModal:title'),
      children: (
        <LocaleSelector
          onSuccess={closeModal}
          closeModal={closeModal}
          translatedSlugs={translatedSlugs}
        />
      ),
      allowOverflow: true,
    });
    openModal();
  };

  // Careful: When footer bg color changes to not white - the main body content should have a min-height.
  return (
    <>
      <Section className="border-t !pt-12 !pb-8">
        <div className="container text-lg text-gray-200">
          <div className="grid grid-cols-3 gap-x-6 gap-y-12 font-rooney font-medium text-lg text-gray-500 lg:grid-cols-4 lg:gap-8">
            <div className="flex justify-between sm:justify-normal flex-col col-span-3 items-center sm:items-start lg:col-span-1">
              <ECLink href="/" className="block w-32 sm:w-40 mb-8">
                <ECImage
                  img={{ src: '/images/logo-brand-light.svg', alt: 'Edgard &amp; Cooper' }}
                  className="w-full"
                  width={160}
                  height={80}
                />
              </ECLink>
              <ul className="text-white flex gap-x-8 mb-8">
                <li>
                  <Link
                    href={staticTranslate(
                      {
                        be: 'https://www.facebook.com/EdgardCooperBE',
                        fr: 'https://www.facebook.com/EdgardCooperFR',
                        nl: 'https://www.facebook.com/EdgardCooperBE',
                        de: 'https://www.facebook.com/EdgardCooperDE',
                        it: 'https://www.facebook.com/EdgardCooperIT',
                        es: 'https://www.facebook.com/EdgardCooperES',
                        fi: 'https://www.facebook.com/EdgardCooperFI',
                        default: 'https://www.facebook.com/EdgardCooper',
                      },
                      'countryCode',
                    )}
                    target="_blank"
                    className="text-gray-500 flex justify-center items-center
                    rounded transition hover:text-cyan-400"
                  >
                    <Icon name="facebook" className="w-6 h-6" label={t('followFacebook')} />
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.instagram.com/edgardcooper/"
                    target="_blank"
                    className="text-gray-500 flex justify-center items-center
                    rounded transition hover:text-cyan-400"
                  >
                    <Icon name="instagram" className="w-6 h-6" label={t('followInstagram')} />
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.tiktok.com/@edgardcooper"
                    target="_blank"
                    className="text-gray-500 flex justify-center items-center
                    rounded transition hover:text-cyan-400"
                  >
                    <Icon name="tiktok" className="w-6 h-6" label={t('followTiktok')} />
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.linkedin.com/company/edgard&cooper"
                    target="_blank"
                    className="text-gray-500 flex justify-center items-center
                    rounded transition hover:text-cyan-400"
                  >
                    <Icon name="linkedin" className="w-6 h-6" label={t('followLinkedin')} />
                  </Link>
                </li>
              </ul>
            </div>

            {/* ---- desktop + tablet footer navigation menu items ---- */}

            {/*  Shop */}
            <div className="hidden sm:block">
              <span className="block text-xl mb-6 font-bold">{t('titleShop')}</span>
              <ul className="flex flex-col flex-gap-y-4">
                <li>
                  <ECLink
                    href={getPagePath({ path: '/collections/dogs', locale })}
                    className="hover:text-cyan-400 transition"
                    data-testid="shopDogs"
                  >
                    {t('dogs')}
                  </ECLink>
                </li>
                <li>
                  <ECLink
                    href={getPagePath({ path: '/collections/cats', locale })}
                    className="hover:text-cyan-400 transition"
                  >
                    {t('cats')}
                  </ECLink>
                </li>
              </ul>
            </div>

            {/*  Learn */}
            <div className="hidden sm:block">
              <span className="block text-xl mb-6 font-bold">{t('titleLearn')}</span>
              <ul className="flex flex-col flex-gap-y-4">
                <li>
                  <Link href="/our-food/" className="hover:text-cyan-400 transition">
                    {t('ourFood')}
                  </Link>
                </li>
                <li>
                  <Link href="/our-story/" className="hover:text-cyan-400 transition">
                    {t('ourStory')}
                  </Link>
                </li>
                <li>
                  <Link href="/our-planet/" className="hover:text-cyan-400 transition">
                    {t('ourPlanet')}
                  </Link>
                </li>
                <li>
                  <Link href="/blog/" className="hover:text-cyan-400 transition">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://cdn.edgardcooper.com/pdf/edgard_cooper_impact_report_2023.pdf"
                    className="hover:text-cyan-400 transition"
                    target="_blank"
                    onClick={() => {
                      trackCtaClicked({ ctaId: `site_navigation_our_impact_report` });
                    }}
                  >
                    {t('impactReport')}
                  </Link>
                </li>
                <li>
                  <Link
                    href={staticTranslate(
                      {
                        nl: 'https://faq.edgardcooper.com/nl/articles/9263656-a-message-from-edgard-cooper-s-founders',
                        fr: 'https://faq.edgardcooper.com/fr/articles/9263656-a-message-from-edgard-cooper-s-founders',
                        de: 'https://faq.edgardcooper.com/de/articles/9263656-a-message-from-edgard-cooper-s-founders',
                        it: 'https://faq.edgardcooper.com/it/articles/9263656-a-message-from-edgard-cooper-s-founders',
                        es: 'https://faq.edgardcooper.com/es/articles/9263656-a-message-from-edgard-cooper-s-founders',
                        en: 'https://faq.edgardcooper.com/en/articles/9263656-a-message-from-edgard-cooper-s-founders',
                        default:
                          'https://faq.edgardcooper.com/en/articles/9263656-a-message-from-edgard-cooper-s-founders',
                      },
                      'locale',
                    )}
                    className="hover:text-cyan-400 transition"
                    target="_blank"
                    onClick={() => {
                      trackCtaClicked({ ctaId: `site_navigation_message_founders` });
                    }}
                  >
                    {t('foundersLetter')}
                  </Link>
                </li>
              </ul>
            </div>

            {/* Support */}
            <div className="hidden sm:block">
              <span className="block text-xl mb-6 font-bold">{t('titleSupport')}</span>
              <ul className="flex flex-col flex-gap-y-4">
                {/* Contact */}
                <li>
                  <Link
                    href={staticTranslate(staticTranslations.contact, 'countryCode')}
                    className="hover:text-cyan-400 transition"
                    target="_blank"
                  >
                    {t('contact')}
                  </Link>
                </li>
                {/* Delivery & returns */}
                <li>
                  <Link
                    href={staticTranslate(staticTranslations.shippingAndReturns, 'countryCode')}
                    target="_blank"
                    className="hover:text-cyan-400 transition"
                  >
                    {t('shippingAndReturns')}
                  </Link>
                </li>
                {/* FAQ */}
                <li>
                  <Link
                    href={staticTranslate(staticTranslations.faq, 'countryCode')}
                    target="_blank"
                    className="hover:text-cyan-400 transition"
                  >
                    {t('faq')}
                  </Link>
                </li>
                {/* Careers */}
                <li>
                  <Link
                    href="https://careers.edgardcooper.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-cyan-400 transition"
                  >
                    {t('careers')}
                    <span className="inline-block ml-2">
                      <Sticker
                        sticker={{
                          displayText: t('hiring'),
                          backgroundHexColor: '#df1995', // pink-400
                          fontHexColor: '#ffffff',
                          name: 'promoLabel',
                          // Not needed, but is required for type
                          bannerActiveForCountries: [],
                        }}
                      />
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* ---- mobile footer navigation menu items ---- */}
          <div className="flex flex-col flex-1 border border-blue-200 sm:hidden rounded-lg">
            <nav className="flex flex-col flex-1">
              <ul className="flex flex-col divide-y divide-blue-200 px-4">
                {getFooterNavItems(t, staticTranslate, staticTranslations, locale).map(
                  (navItem) => (
                    <li
                      key={navItem.label}
                      className="[&_button]:px-0 [&_button]:text-lg [&_button]:py-3 [&_a]:px-0 [&_a]:text-base [&_a]:leading-5 [&_a]:font-medium leading-7 py-1"
                    >
                      <MobileNavItemLink navItem={navItem} />
                    </li>
                  ),
                )}
              </ul>
            </nav>
          </div>

          {/* sustainability */}
          <div className="flex flex-row gap-x-8 items-center my-12 justify-center sm:justify-start">
            <a href="/b-corp" target="_blank" rel="noreferrer" className="w-14">
              <ECImage
                img={{
                  src: '/images/award/logo-bcorp.png',
                  alt: 'B corp',
                }}
                className="h-14 mx-auto"
              />
            </a>
            <ECImage
              img={{ src: '/images/award/logo-bio.png', alt: 'Bio Award' }}
              className="w-14"
              width={48}
              height={48}
            />
            <ECImage
              img={{
                src: '/images/award/logo-ethical.png',
                alt: 'Good shopping award',
              }}
              className="w-14"
              width={48}
              height={48}
            />
          </div>

          <div className="flex justify-between mb- mb-6 flex-col lg:flex-row gap-y-8 sm:gap-y-12">
            <div className="flex flex-col gap-3 sm:flex-row items-center font-rooney flex-gap-x-6 text-sm sm:text-base font-medium text-gray-500 self-center sm:self-start">
              {/* country-language selector */}
              <button
                className="!rounded-full border border-gray-75 !px-4 !py-2 w-fit mb-5 sm:mb-0 bg-white hover:bg-cyan-200 hover:border-cyan-200 transition"
                onClick={() => {
                  openLocaleModal();
                }}
                aria-label={t('countryLanguage')}
                type="button"
              >
                <LocaleSwitcher mobile />
              </button>
              {/* terms & conditions & privacy policy & DE Impressum */}
              <Link
                href={staticTranslate(staticTranslations.termsAndConditions, 'countryCode')}
                target="_blank"
                className="hover:text-cyan-400 transition"
              >
                {t('termsAndConditions')}
              </Link>
              <Link
                href={staticTranslate(staticTranslations.privacyPolicy, 'countryCode')}
                target="_blank"
                className="hover:text-cyan-400 transition"
              >
                {t('privacyPolicy')}
              </Link>
              {!isLoadingCountryContext && countryCode === 'DE' && (
                <a
                  href="https://faq.edgardcooper.com/de/articles/9243250-impressum"
                  target="_blank"
                  className="hover:text-cyan-400 transition"
                  rel="noreferrer"
                >
                  Impressum
                </a>
              )}
              {!isLoadingCountryContext && countryCode === 'IT' && (
                <a
                  href={staticTranslate(staticTranslations.bCorpCertificationNotice, 'locale')}
                  target="_blank"
                  className="hover:text-cyan-400 transition"
                  rel="noreferrer"
                >
                  {t('bCorpCertificationNotice')}
                </a>
              )}
            </div>
            {/* payment methods */}
            <div className="mx-auto sm:mx-0">
              <PaymentMethods />
            </div>
          </div>

          {/* copyright */}
          <div className="font-rooney font-medium text-gray-500 text-xs w-full text-center sm:text-left">
            © {new Date().getFullYear()} Edgard&Cooper All Rights Reserved.
          </div>
        </div>
      </Section>
    </>
  );
};

export default Footer;
