type NumberFormatOptions = {
  [option: string]: unknown;
};

const formatPrice = (
  price: number | string,
  currency: string,
  locale: string,
  formatOptions?: NumberFormatOptions,
): string => {
  const priceNum = typeof price === 'string' ? parseFloat(price) : price;
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    ...formatOptions,
  }).format(priceNum);
};

export default formatPrice;
