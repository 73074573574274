import CartProduct from 'components/parts/CartProduct';
import { useCart } from 'context/ShopifyCartProvider';

type CartListProps = {
  usedInDrawer?: boolean;
};
const CartList: React.FC<CartListProps> = ({ usedInDrawer }) => {
  const { cart, cartStates } = useCart();

  if (cartStates.isCartLoading) {
    return <></>;
  }

  return (
    <ul className={`${usedInDrawer ? 'w-full' : ''}`}>
      {cart.lines.edges.map(({ node }) => (
        <li
          key={node.id}
          className={`${
            usedInDrawer ? 'pb-4 mb-4 border-blue-200' : 'pb-6 mb-6 border-gray-75'
          } last:pb-0 last:mb-0 last:border-0 border-solid border-b`}
        >
          <CartProduct cartItem={node} usedInDrawer={usedInDrawer} />
        </li>
      ))}
    </ul>
  );
};

export default CartList;
