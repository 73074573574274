import { Disclosure } from '@headlessui/react';
import { useRouter } from 'next/router';

import ProductQuizCard from 'components/parts/ProductQuizCard';
import Sticker from 'components/parts/Sticker';
import { ECLink, Icon } from 'components/ui';
import useTrackingEvents from 'services/analytics/useTrackingEvents';
import { getPagePath } from 'utils/page-paths';

import { NavItemProps } from './navItems';

const MobileNavItemLink: React.FC<{ navItem: NavItemProps }> = ({ navItem }) => {
  const { label, url, children } = navItem;
  const { trackCtaClicked } = useTrackingEvents();

  const { locale } = useRouter();

  const onClickNavItem = (item: NavItemProps) => {
    if (item?.trackingId) {
      trackCtaClicked({ ctaId: `site_navigation_${item.trackingId}` });
    }
  };

  // eslint-disable-next-line no-nested-ternary
  return children ? (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            className="flex py-4 px-6 w-full text-left
          items-center justify-between font-rooney text-gray-500 font-bold text-xl hover:text-cyan-400"
          >
            {label}
            <Icon
              name="chevron-down-narrow"
              className={`w-6 transform transition${open ? ' rotate-180' : ''}`}
            />
          </Disclosure.Button>
          <Disclosure.Panel>
            <ul className="pb-4">
              {navItem.children.filter(Boolean).map((subNavItem) => (
                <li key={subNavItem.label}>
                  <ECLink
                    href={subNavItem.url}
                    className="flex items-center px-6 py-2 font-rooney text-gray-500 text-lg font-medium hover:text-cyan-400 pl-8"
                    onClick={() => onClickNavItem(subNavItem)}
                  >
                    {subNavItem.label}
                    {subNavItem?.stickerLabel && (
                      <span className="inline-flex ml-2">
                        <Sticker
                          outerClassName="!text-xs !py-0.5 !px-1.5 !mb-0"
                          sticker={{
                            displayText: subNavItem.stickerLabel,
                            backgroundHexColor: '#df1995', // pink-400
                            fontHexColor: '#ffffff',
                            name: 'name',
                            bannerActiveForCountries: [],
                          }}
                        />
                      </span>
                    )}
                  </ECLink>
                </li>
              ))}
            </ul>
            <ul>
              {navItem.url === getPagePath({ path: '/collections/dogs', locale }) ||
              navItem.url === getPagePath({ path: '/collections/cats', locale }) ? (
                <li className="min-w-[250px]">
                  <ProductQuizCard
                    range={
                      navItem.url === getPagePath({ path: '/collections/cats', locale })
                        ? 'cat'
                        : 'dog'
                    }
                    ctaId="site_navigation_product_quiz"
                    isCard={false}
                    theme="light"
                    size="medium"
                    className="px-8"
                  />
                </li>
              ) : (
                <></>
              )}
            </ul>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  ) : (
    <ECLink
      href={url}
      className="font-rooney text-gray-500 text-xl font-bold block py-4 px-6 transition leading-8 hover:text-cyan-400"
      onClick={() => onClickNavItem(navItem)}
    >
      {label}
    </ECLink>
  );
};

export default MobileNavItemLink;
