import { getPagePath } from 'utils/page-paths';

export interface NavItemProps {
  url: string;
  label: string;
  external?: boolean;
  children?: NavItemProps[];
  specificForCountries?: string[];
  trackingId?: string;
  stickerLabel?: string;
}

const getFooterNavItems = (
  t: (key: string) => string,
  staticTranslate,
  staticTranslations,
  locale: string,
): NavItemProps[] => [
  {
    url: '/',
    label: t('titleShop'),
    children: [
      {
        url: getPagePath({ path: '/collections/dogs', locale }),
        label: t('dogs'),
      },
      {
        url: getPagePath({ path: '/collections/cats', locale }),
        label: t('cats'),
      },
    ],
  },
  {
    url: '/',
    label: t('titleLearn'),
    children: [
      {
        url: '/our-food/',
        label: t('ourFood'),
      },
      {
        url: '/our-story/',
        label: t('ourStory'),
      },
      {
        url: '/our-planet/',
        label: t('ourPlanet'),
      },
      {
        url: '/blog/',
        label: 'Blog',
      },
      {
        url: 'https://cdn.edgardcooper.com/pdf/edgard_cooper_impact_report_2023.pdf',
        label: t('impactReport'),
        trackingId: 'our_impact_report',
      },
    ],
  },
  {
    url: '/',
    label: t('titleSupport'),
    children: [
      {
        url: staticTranslate(staticTranslations.contact, 'countryCode'),
        label: t('contact'),
      },
      {
        url: staticTranslate(staticTranslations.shippingAndReturns, 'countryCode'),
        label: t('shippingAndReturns'),
      },
      {
        url: staticTranslate(staticTranslations.faq, 'countryCode'),
        label: t('faq'),
      },
      {
        url: 'https://careers.edgardcooper.com/',
        label: t('careers'),
        stickerLabel: t('hiring'),
      },
    ],
  },
];

export default getFooterNavItems;
