import { gql } from 'graphql-request';

import { bffRequest } from 'api/client/bff-request';

const query = gql`
  query ($countryCode: String!, $locale: String!, $limit: Int) {
    getStickerBanners(bannerActiveForCountries: $countryCode, locale: $locale, limit: $limit) {
      backgroundHexColor
      displayText
      fontHexColor
      link
      openLinkInNewTab
    }
  }
`;

type QueryVariables = {
  countryCode: string;
  locale: string;
  limit?: number;
};

const getStickerBanners = async (variables: QueryVariables): Promise<boolean> =>
  bffRequest<boolean>({ query, variables }).then((data) => data);

export default getStickerBanners;
