import Link from 'next/link';
import { useRouter } from 'next/router';

import { Alert, Button, ECImage, ECLink, Icon, Spinner } from 'components/ui';
import { useCountryContext } from 'context/CountryContextProvider';
import { useCart } from 'context/ShopifyCartProvider';
import useTrackingEvents from 'services/analytics/useTrackingEvents';
import { useTranslations } from 'services/translations/TranslationsProvider';
import formatPrice from 'utils/formatPrice';
import { getPagePath } from 'utils/page-paths';

import CartList from '../CartList';

const CartDrawer = () => {
  const { t } = useTranslations('cart');
  const { cart, cartStates } = useCart();
  const { trackCtaClicked } = useTrackingEvents();
  const { currencyCode, freeShippingFrom: minValue, countryCode } = useCountryContext();
  const cartIsFilled = cart?.lines?.edges?.length > 0;
  const router = useRouter();
  const { locale } = router;
  const amountLeftToUnlock = minValue - cart?.cost?.subtotalAmount?.amount;
  const percentageLeftToUnlock = ((minValue - amountLeftToUnlock) / minValue) * 100;
  const freeShippingUnlocked = amountLeftToUnlock <= 0;
  const formattedPrice = formatPrice(amountLeftToUnlock, currencyCode, locale);
  const formattedShippingPrice = formatPrice(minValue, currencyCode, locale);

  const itemsInCartAmount = cart?.totalQuantity;
  const itemsInTotalTranslated =
    cart?.totalQuantity === 1 ? (
      <>1 {t('cartDrawer:oneItem')}</>
    ) : (
      <>{t('cartDrawer:numberOfItems', { amount: itemsInCartAmount })}</>
    );

  const onClickGoToCart = () => {
    trackCtaClicked({ ctaId: 'cart_drawer_to_cart' });
  };

  if (cartStates.isCartError) {
    return (
      <div className="container my-4 md:my-8">
        <Alert variant="error" message={t('cartGlobal:errorMessageDefault')} />
      </div>
    );
  }

  if (cartStates.isCartLoading) {
    return (
      <>
        <div className="flex my-4 justify-center">
          <Spinner size="small" />
        </div>
      </>
    );
  }
  if (!cartIsFilled) {
    return (
      <div className="font-rooney h-full">
        {countryCode !== 'OTHER' && (
          <div className="bg-cyan-200 text-gray-500 text-xs px-4 py-2 text-center font-bold">
            <span>{t('cartDrawer:freeShippingFrom', { amount: formattedShippingPrice })}</span>
          </div>
        )}
        <div className="px-4 py-8 text-gray-500 font-bold">
          <div className="mb-6 text-center text-base lg:text-xl">
            <p>
              {t('cartDrawer:cartEmpty')} <br className="maxSm:hidden" />{' '}
              {t('cartDrawer:cartEmptyWhyNotFill')}
            </p>
          </div>
          <div className="flex flex-col space-y-4">
            <ECLink
              href={getPagePath({ path: '/collections/dogs', locale })}
              className="flex items-center justify-between py-2 pl-2 pr-6 border border-blue-200 rounded-lg hover:border-gray-100 transition group"
            >
              <div className="flex space-x-4 items-center">
                <div className="bg-cyan-200 h-14 w-14 lg:h-16 lg:w-16 rounded-lg relative">
                  <ECImage
                    img={{ src: '/images/cart/dog-looking-up.png', alt: 'Dog looking up' }}
                    className="rounded-lg absolute left-0 pt-[3px] bottom-0 h-14 w-14 lg:h-16 lg:w-16"
                  />
                </div>
                <span className="text-base lg:text-lg">{t('header:dogs')}</span>
              </div>
              <Icon name="chevron-right" className="w-6 group-hover:translate-x-1 transition" />
            </ECLink>

            <ECLink
              href={getPagePath({ path: '/collections/cats', locale })}
              className="flex items-center justify-between py-2 pl-2 pr-6 border border-blue-200 rounded-lg hover:border-gray-100 transition group"
            >
              <div>
                <div className="flex items-center space-x-4">
                  <div className="bg-cyan-200 w-14 h-14 lg:h-16 lg:w-16 rounded-lg relative bottom-0 left-0">
                    <ECImage
                      img={{ src: '/images/cart/cat-looking-up.png', alt: 'Cat looking up' }}
                      className="rounded-lg absolute left-0 bottom-0 h-14 w-14 lg:w-16 lg:h-16"
                    />
                  </div>
                  <span className="text-base lg:text-lg">{t('header:cats')}</span>
                </div>
              </div>
              <Icon name="chevron-right" className="w-6 group-hover:translate-x-1 transition" />
            </ECLink>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="font-rooney relative flex flex-col h-full">
      {countryCode !== 'OTHER' && (
        <>
          <div className="bg-cyan-200 text-gray-500 text-xs px-4 py-2 text-center font-bold">
            {!freeShippingUnlocked && (
              <span className="">
                {t('cartDrawer:xAwayFromFreeShipping', { amount: formattedPrice })}
              </span>
            )}
            {freeShippingUnlocked && (
              <>
                <span>{t('cartDrawer:earnedFreeShipping')}</span> <span>🎉</span>
              </>
            )}
          </div>
          <div className="relative">
            <div className="h-[2px] bg-cyan-200 rounded-lg relative z-0 overflow-hidden">
              <div
                className="h-[2px] bg-cyan-400 rounded-lg transition-all duration-500"
                style={{
                  width: `${percentageLeftToUnlock}%`,
                }}
              />
            </div>
          </div>
        </>
      )}
      <div className="p-4 overflow-scroll scrollbar-hide flex grow">
        <CartList usedInDrawer />
      </div>

      {/* eslint-disable max-len */}
      <div className="sticky bottom-0 shadow-[0_-2px_8px_0_rgba(231,238,239,0.72)] bg-white rounded-t-2xl border border-b-0 border-l-0 border-r-0 border-t-blue-200 z-50 py-6 px-4">
        <div className="flex flex-col text-gray-500">
          <div className="flex gap-1 items-center justify-between text-lg md:text-xl font-bold">
            <span>
              {t('cartDrawer:summarySubtotal')} ({itemsInTotalTranslated})
            </span>
            <span>
              {formatPrice(
                cart?.cost?.subtotalAmount?.amount,
                cart?.cost?.subtotalAmount?.currencyCode,
                locale,
              )}
            </span>
          </div>
          {freeShippingUnlocked && (
            <div className="flex justify-between items-center text-base font-medium mt-1">
              <span className="">{t('cartDrawer:shipping')}</span>
              <span className="">{t('cartDrawer:free')}</span>
            </div>
          )}
        </div>
        <Link href="/cart" data-testid="goToCartPageBtn">
          <Button
            color="green-550"
            className="w-full mt-4 md:mt-6 active:bg-green-575"
            onClick={() => onClickGoToCart()}
            loading={cartStates.isCartUpdating}
          >
            {t('cartDrawer:goToCart')}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default CartDrawer;
