type Path = '/collections/dogs' | '/collections/cats' | '/collections/<slug>';

type Language = 'en' | 'nl' | 'fr' | 'de' | 'it' | 'es' | 'default';

type PathTranslation = Record<Language, string>;

const PATH_TRANSLATIONS: Record<Path, PathTranslation> = {
  '/collections/dogs': {
    en: '/collections/dogs',
    nl: '/collections/dogs',
    fr: '/collections/dogs',
    de: '/collections/dogs',
    it: '/collections/dogs',
    es: '/collections/dogs',
    default: '/collections/dogs',
  },
  '/collections/cats': {
    en: '/collections/cats',
    nl: '/collections/cats',
    fr: '/collections/cats',
    de: '/collections/cats',
    it: '/collections/cats',
    es: '/collections/cats',
    default: '/collections/cats',
  },
  '/collections/<slug>': {
    en: '/collections/<slug>',
    nl: '/collections/<slug>',
    fr: '/collections/<slug>',
    de: '/collections/<slug>',
    it: '/collections/<slug>',
    es: '/collections/<slug>',
    default: '/collections/<slug>',
  },
};

/**
 * As we don't have a super clean solution for translated paths yet,
 * we are currently working with rewrites to achieve this functionality.
 * If we want to be able to link to pages with translated paths,
 * we can use this function to get the correct path for a given page.
 */
// eslint-disable-next-line import/prefer-default-export
export const getPagePath = <TPath extends Path>({
  path,
  locale,
  slug,
}: {
  path: TPath;
  locale: string;
  slug?: TPath extends `${infer _T}<slug>${infer _U}` ? string : never;
}): string => PATH_TRANSLATIONS[path]?.[locale]?.replace('<slug>', slug ?? '');
