import * as React from 'react';
import { useEffect, useState } from 'react';

import classNames from 'classnames';

interface Props {
  initialQuantity: number;
  onQuantityChanged: (quantity: number) => void;
  disabled?: boolean;
  minimum?: number;
  className?: string;
  usedInDrawer?: boolean;
}

const QuantityPicker: React.FC<Props> = ({
  onQuantityChanged,
  initialQuantity,
  disabled,
  minimum = 1,
  className: additionalClassname,
  usedInDrawer,
}) => {
  const [quantity, setQuantity] = useState(initialQuantity);

  const increment = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    onQuantityChanged(newQuantity);
  };

  const decrement = () => {
    const newQuantity = quantity > minimum ? quantity - 1 : minimum;
    if (newQuantity !== quantity) {
      setQuantity(newQuantity);
      onQuantityChanged(newQuantity);
    }
  };

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  const baseStylesQTP = {
    minWidth: '110px',
    maxWidth: '135px',
  };
  const QTPStyles = usedInDrawer
    ? {
        ...baseStylesQTP, // Spread the base styles
        minWidth: '110px', // Override minWidth for wide rendering
        maxWidth: '120px', // Override maxWidth for wide rendering
      }
    : baseStylesQTP; // Use base styles if not rendering wide

  return (
    <div
      className={classNames(
        'quantity-picker bg-white border rounded-lg text-gray-300 flex max-w-full overflow-hidden font-rooney border-gray-100 text-base font-bold',
        disabled && 'pointer-events-none',
        additionalClassname,
      )}
      style={QTPStyles}
    >
      <button
        type="button"
        onClick={decrement}
        className={classNames(
          'flex focus:outline-none cursor-pointer text-gray-300 justify-center items-center transition',
          'active:bg-gray-50 active:text-gray-400 sm:hover:bg-gray-50 sm:hover:text-gray-400',
        )}
        style={{ minWidth: '42px' }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <input
        type="text"
        value={quantity}
        name="quantity"
        className={classNames(
          'border-0 focus:ring-0 text-center cursor-default text-base px-2 block text-gray-400 w-full',
        )}
        style={{ maxWidth: '50px' }}
        readOnly
      />
      <button
        type="button"
        onClick={increment}
        className={classNames(
          'flex focus:outline-none cursor-pointer text-gray-300 justify-center items-center transition ',
          'active:bg-gray-50 active:text-gray-400 sm:hover:bg-gray-50 sm:hover:text-gray-400',
        )}
        style={{ minWidth: '42px' }}
        data-testid="add1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};

export default QuantityPicker;
