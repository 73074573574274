import Icon from 'components/ui/Icon';

const PaymentMethods: React.FC = () => {
  const icons = {
    mastercard: 'payment-mastercard',
    visa: 'payment-visa',
    paypal: 'payment-paypal',
    bancontact: 'payment-bancontact',
    amex: 'payment-amex',
    ideal: 'payment-ideal',
    cartebancaire: 'payment-cartebancaire',
  };

  return (
    <ul className="flex flex-gap-2 flex-wrap">
      {Object.keys(icons).map((icon) => (
        <li key={icon}>
          <Icon src={`/images/payment/${icons[icon]}.svg`} className="w-8" label={icon} />
        </li>
      ))}
    </ul>
  );
};

export default PaymentMethods;
