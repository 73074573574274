import { Popover } from '@headlessui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import ProductQuizCard from 'components/parts/ProductQuizCard';
import Sticker from 'components/parts/Sticker';
import { ECLink, Icon } from 'components/ui';
import useTrackingEvents from 'services/analytics/useTrackingEvents';
import { getPagePath } from 'utils/page-paths';

import { NavItemProps } from './navItems';

const DesktopNavItemLink: React.FC<{ navItem: NavItemProps }> = ({ navItem }) => {
  const { label, url, children, external } = navItem;
  const { trackCtaClicked } = useTrackingEvents();

  const { locale } = useRouter();

  const onClickNavItem = (item: NavItemProps) => {
    if (item?.trackingId) {
      trackCtaClicked({ ctaId: `site_navigation_${item.trackingId}` });
    }
  };

  return children ? (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            as="a"
            className="font-rooney text-gray-500 font-bold text-lg appearance-none whitespace-nowrap py-2 px-2 flex items-center cursor-pointer transition
          hover:text-cyan-400"
            tabIndex={0}
          >
            {navItem.label}
            <Icon
              name="chevron-down"
              className={`w-5 ml-1 ${open ? 'transition transform rotate-180' : ''}`}
            />
          </Popover.Button>
          <Popover.Panel
            className="absolute left-1/2 transform -translate-x-1/2 translate-y-2 z-10
          bg-white border border-blue-200 rounded-lg shadow min-w-[220px] overflow-hidden will-change-transform"
          >
            <ul className="flex flex-col p-2">
              {navItem.children.filter(Boolean).map((subNavItem) => (
                <li key={subNavItem.label}>
                  <ECLink
                    href={subNavItem.url}
                    target={subNavItem.external ? '_blank' : '_self'}
                    className="font-rooney text-gray-500 text-base font-bold px-3 py-2 rounded-lg whitespace-nowrap transition flex items-center
                    hover:bg-blue-200 hover:text-cyan-500"
                    onClick={() => {
                      onClickNavItem(subNavItem);
                      close();
                    }}
                  >
                    {subNavItem.label}
                    {subNavItem?.stickerLabel && (
                      <span className="inline-flex ml-2">
                        <Sticker
                          outerClassName="!text-xs !py-0.5 !px-1.5 !mb-0"
                          sticker={{
                            displayText: subNavItem.stickerLabel,
                            backgroundHexColor: '#df1995', // pink-400
                            fontHexColor: '#ffffff',
                            name: 'name',
                            bannerActiveForCountries: [],
                          }}
                        />
                      </span>
                    )}
                  </ECLink>
                </li>
              ))}
            </ul>
            <ul>
              {navItem.url === getPagePath({ path: '/collections/dogs', locale }) ||
              navItem.url === getPagePath({ path: '/collections/cats', locale }) ? (
                <li className="min-w-[250px]">
                  <ProductQuizCard
                    range={
                      navItem.url === getPagePath({ path: '/collections/cats', locale })
                        ? 'cat'
                        : 'dog'
                    }
                    ctaId="site_navigation_product_quiz"
                    isCard={false}
                    className="px-5"
                  />
                </li>
              ) : (
                <></>
              )}
            </ul>
          </Popover.Panel>
        </>
      )}
    </Popover>
  ) : (
    <Link
      href={url}
      target={external ? '_blank' : '_self'}
      className="font-rooney text-gray-500 text-lg font-bold transition py-2 px-2 whitespace-nowrap hover:text-cyan-400"
      onClick={() => onClickNavItem(navItem)}
    >
      {label}
    </Link>
  );
};

export default DesktopNavItemLink;
