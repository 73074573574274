import { useRouter } from 'next/router';

import { useCountryContext } from 'context/CountryContextProvider';

interface UseStaticTranslateReturn {
  staticTranslate: (
    translations: { [p: string]: string },
    priority: 'locale' | 'countryCode',
  ) => string;
  staticTranslations: { [key: string]: { [key: string]: string } };
}

const useStaticTranslate = (): UseStaticTranslateReturn => {
  const { countryCode } = useCountryContext();
  const { locale } = useRouter();

  const staticTranslate = (
    translations: { [key: string]: string; default: string },
    priority: 'locale' | 'countryCode',
  ): string => {
    if (priority === 'locale') {
      return (
        translations[`${locale}-${countryCode}`] ||
        translations[locale] ||
        translations[countryCode?.toLowerCase()] ||
        translations.default
      );
    }

    return (
      translations[countryCode?.toLowerCase()] ||
      translations[`${locale}-${countryCode}`] ||
      translations[locale] ||
      translations.default
    );
  };

  const staticTranslations = {
    termsAndConditions: {
      fr: 'https://faq.edgardcooper.com/fr/articles/8853487-conditions-generales',
      nl: 'https://faq.edgardcooper.com/nl/articles/8853487-algemene-voorwaarden',
      de: 'https://faq.edgardcooper.com/de/articles/8853487-allgemeine-geschaftsbedingungen',
      it: 'https://faq.edgardcooper.com/it/articles/8853487-condizioni-generali',
      es: 'https://faq.edgardcooper.com/es/articles/8853487-modalidades-y-condiciones',
      default: 'https://faq.edgardcooper.com/en/articles/8853487-terms-conditions',
    },
    privacyPolicy: {
      fr: 'https://faq.edgardcooper.com/fr/articles/8856442-politique-de-confidentialite',
      nl: 'https://faq.edgardcooper.com/nl/articles/8856442-privacy-policy',
      de: 'https://faq.edgardcooper.com/de/articles/8856442-datenschutz-bestimmungen',
      it: 'https://faq.edgardcooper.com/it/articles/8856442-politica-sulla-riservatezza',
      es: 'https://faq.edgardcooper.com/es/articles/8856442-politica-de-privacidad',
      default: 'https://faq.edgardcooper.com/en/articles/8856442-privacy-policy',
    },
    faq: {
      fr: 'https://faq.edgardcooper.com/fr',
      nl: 'https://faq.edgardcooper.com/nl',
      de: 'https://faq.edgardcooper.com/de',
      it: 'https://faq.edgardcooper.com/it',
      es: 'https://faq.edgardcooper.com/es',
      default: 'https://faq.edgardcooper.com/en',
    },
    faqSubscription: {
      fr: 'https://faq.edgardcooper.com/fr/collections/8063898-mon-abonnement',
      nl: 'https://faq.edgardcooper.com/nl/collections/8063898-abonnement',
      de: 'https://faq.edgardcooper.com/de/collections/8063898-abonnement',
      it: 'https://faq.edgardcooper.com/it/collections/8063898-il-mio-abbonamento',
      es: 'https://faq.edgardcooper.com/es/collections/8063898-suscripcion',
      default: 'https://faq.edgardcooper.com/en/collections/8063898-subscription',
    },
    shippingAndReturns: {
      fr: 'https://faq.edgardcooper.com/fr/collections/8063880-livraison-retour',
      nl: 'https://faq.edgardcooper.com/nl/collections/8063880-bezorgen-retournen',
      de: 'https://faq.edgardcooper.com/de/collections/8063880-lieferung-ruckgabe',
      it: 'https://faq.edgardcooper.com/it/collections/8063880-consegne-e-restituzioni',
      es: 'https://faq.edgardcooper.com/es/collections/8063880-envio-y-devolucion',
      default: 'https://faq.edgardcooper.com/en/collections/8063880-delivery-returns-order-issues',
    },
    contact: {
      nl: 'https://faq.edgardcooper.com/nl/articles/8856453-contacteer-edgard-cooper',
      fr: 'https://faq.edgardcooper.com/fr/articles/8856453-contactez-edgard-cooper',
      de: 'https://faq.edgardcooper.com/de/articles/8856453-kontakt-edgard-cooper',
      it: 'https://faq.edgardcooper.com/it/articles/8856453-contattate-edgard-cooper',
      es: 'https://faq.edgardcooper.com/es/articles/8856453-contacte-con-edgard-cooper',
      default: 'https://faq.edgardcooper.com/en/articles/8856453-contact-edgard-cooper',
    },
    bCorpCertificationNotice: {
      nl: 'https://faq.edgardcooper.com/nl/articles/9334807-kennisgeving-van-certificering-b-corp',
      fr: 'https://faq.edgardcooper.com/fr/articles/9334807-note-d-information-sur-la-certification-b-corp',
      de: 'https://faq.edgardcooper.com/de/articles/9334807-certification-notice-fur-b-corp',
      it: 'https://faq.edgardcooper.com/it/articles/9334807-nota-sulla-certificazione-b-corp',
      es: 'https://faq.edgardcooper.com/es/articles/9334807-aviso-de-certificacion-b-corp',
      en: 'https://faq.edgardcooper.com/en/articles/9334807-b-corp-certification-notice',
      default: 'https://faq.edgardcooper.com/en/articles/9334807-b-corp-certification-notice',
    },
  };

  return { staticTranslate, staticTranslations };
};

export default useStaticTranslate;
